import { Controller } from "stimulus"
import { CartController } from './cart_controller';
import { SupplementSelectionHandler } from '../classes/supplement_selection_handler';
import { MainController } from './main_controller';
import PerfectScrollbar from 'perfect-scrollbar';
import { numberToEuro } from '../shared/number_to_euro';

export default class BasketmenuController extends MainController {
  static targets = ['slide']

  connect() {
    this.element['controller'] = this
    this.supplementSelectionHandler = new SupplementSelectionHandler
    $.each($('.select_dish_selection'), (i, dish) => {
      this.updateDishSetSelection($(dish))
    })  
  }

  updateDishSetSelection(select) {
    let params = {
      id: $('#form_slider').data().id,
      dish_id: select.parents('.choose_dish').eq(0).data().id,
      dish_set_selection_id: select.data().dishSelectionId,
      product_unique_id: select.find(':selected').data().uniqueId,
      dish_set_id: select.find(':selected').data().dishSetId
    }
    $.get(`/basket_menus/add_products?${new URLSearchParams(params).toString()}`)

  }

  dishSetSelectionChanged(e) {
    this.updateDishSetSelection($(e.currentTarget))
    this.checkValid()
  }  

  supplementChanged(e) {
    this.supplementSelectionHandler.quantityChanged($(e.currentTarget), $(e.currentTarget).data().factor == 'minus' ? -1 : 1)
    this.checkValid(false)
  }  

  submit() {
    let type = $('#form_slider').hasClass('new_basket_menu') ? 'POST' : 'PUT'
    $('.loading_app_container').removeClass('d-none')
    $.ajax({
      type: type,
      url: $('#form_slider').attr('action'),
      data: $('#form_slider').serialize(),
      success: (data) => {
        $('.loading_app_container').addClass('d-none')
        if(data.code == 200) {
          $('.modal').modal('hide')
          this.getControllerByIdentifier('cart').refresh()
        }
      }
    })
  }

  checkValid() {
    let attributes_selected = $('.basket_product_attribute').length == $('.basket_product_attribute :checked').length
    if(attributes_selected) {
      this.calcTotal()
      $('.basket-form-total-price').show()
      $('.product_next_button').addClass('active')
    } else {
      $('.basket-form-total-price').hide()
      $('.product_next_button').removeClass('active')
    }
  }

  calcTotal() {
    let total = parseFloat($(this.element).data().price)
    $.each($('.choose_dish :checked'), (i, dish) => {
      if($(dish).data().pricingImpact) {
        total += parseFloat($(dish).data().pricingImpact)
      }
    })    
    $.each($('.basket-form-supplement-selections .basket-form-quantity-buttons input'), (i, input) => {
      total += parseInt($(input).val()) * parseFloat($(input).data().price)
    })     
    $.each($('.select_dish_selection :selected'), (i, option) => {
      total += parseFloat($(option).data().pricingImpact)
    })
    $('.basket-form-total-price').text(numberToEuro(total))
  }
}
