export class StripeCards {
  constructor(stripe, payment_intent_secret) {
    this.stripe = stripe
    this.payment_intent_secret = payment_intent_secret
  }

  style() {
    return {
      base: {color: '#32325d', fontFamily: '"Helvetica Neue", Helvetica, sans-serif', fontSmoothing: 'antialiased', fontSize: '16px', '::placeholder': {color: '#aab7c4'}},
      invalid: {color: '#fa755a', iconColor: '#fa755a'}
    }
  }

  init_cards() {
    $('.checkout_payment_visa').removeClass('d-none')
    $('.payment_submit_container').removeClass('d-none')
    let elements = this.stripe.elements();
    this.card_elements = {}
    $('.submit_payment').attr('disabled', true)
    $.each(['cardNumber', 'cardExpiry', 'cardCvc'], (index, card_type) => {
      let card_element = elements.create(card_type, {style: this.style(), showIcon: true});
      card_element.mount(`#${card_type}`)
      card_element.on('change', (event) => {
        if (event.error) {$(`#${card_type}`).next().text(event.error.message)}
        $(`#${card_type}`).next().toggleClass('d-none', event.complete)
        setTimeout(() => {$('.submit_payment').attr('disabled', $('.StripeElement--complete').length != 3)}, 100)
      })
      this.card_elements[card_type] = card_element
    })
    $('.checkout_payment_card').removeClass('d-none')
  }

  submit_payment() {
    this.stripe.confirmCardPayment(this.payment_intent_secret, {
      payment_method: {
        card: this.card_elements['cardNumber'],
        billing_details: {
          name: $('.order_user_name').text(),
          email: $('.order_user_email').text(),
          phone: $('#user_phone').val(),
          address: {
            city: $('#user_city').val(),
            line1: $('#user_address').val(),
            postal_code: $('#user_postal_code').val()
          }
        },
      },
    }).then(function(result) {

      if(result.error) {
        $('.loading_app_container').addClass('d-none')
        $('.error_card_submit').removeClass('d-none')
        $('.error_card_submit').text(result.error.message)
      } else if (['requires_capture', 'succeeded'].includes(result.paymentIntent.status)) {
        $.get('/checkout/valid_order', (data) => {$.ajax({url: data.location, dataType: 'script'})})
      }
    });
  }
}
