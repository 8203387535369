import { productHeader } from '../shared/product_header';
import PerfectScrollbar from 'perfect-scrollbar';

function isOverflown(element){
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

$(document).on('turbolinks:load', () => {
  if($('.shop_show_category_list').length) {
    const ps = new PerfectScrollbar('.shop_show_category_list', {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    });
  }
})


$(document).on('click', '.shop_show_item_card', function(e) {
  if(!$(e.currentTarget).data().itemId) { return false; }
  productHeader($(e.currentTarget))
  $.get(`/${$(e.currentTarget).data().type}/new?item_id=${$(e.currentTarget).data().itemId}`)
})
