import { Client, Account } from 'appwrite';

function jwtParser(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function jwtChecker() {
  return new Promise(resolve => {
    if(!localStorage.getItem('jwt')) { resolve('not_signed') }
    let decoded = jwtParser(localStorage.getItem('jwt'))
    if((decoded.exp - moment().unix()) < 300) {
      let appWriteClient = new Client();
      appWriteClient.setEndpoint(process.env.apiEndpoint).setProject(process.env.projectId);
      let account = new Account(appWriteClient);

      account.createJWT().then(function (response) {
        localStorage.setItem('jwt', response.jwt)
        $.ajax({
          method: 'POST',
          url: '/appwrite/signin_user',
          data: {jwt: response.jwt}
        }).done(() => {
          resolve('signed_in')
        })
      }, function (error) {
        console.log(error);
        localStorage.removeItem('jwt');
        logoutUser()
        window.location = '/'
        resolve('error')
      });
    } else {
      resolve('signed_in')
    }  
  })

}
