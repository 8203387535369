import { Controller } from 'stimulus';
import latinize from 'latinize';
import { productHeader } from '../shared/product_header';
import { stickyNav } from '../shared/sticky_nav'
import PerfectScrollbar from 'perfect-scrollbar';
export default class ShopController extends Controller {

  connect() {
/*    this.item_width = $('.shop_show_item_card').eq(0).innerWidth()
    $('.shop_show_item_card').removeClass('d-flex')
    this.max_width = $('.shop_show_section').eq(0).innerWidth() + $('.shop_show_section').eq(0).offset().left - this.item_width
    $('.shop_show_item_card').addClass('d-flex')
    this.recalc_display()*/
    const pSBC=(p,c0,c1,l)=>{
      let r,g,b,P,f,t,h,m=Math.round,a=typeof(c1)=="string";
      if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
      h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=pSBC.pSBCr(c0),P=p<0,t=c1&&c1!="c"?pSBC.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
      if(!f||!t)return null;
      if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
      else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
      a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
      if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
      else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
    }

    pSBC.pSBCr=(d)=>{
      const i=parseInt;
      let n=d.length,x={};
      if(n>9){
        const [r, g, b, a] = (d = d.split(','));
              n = d.length;
        if(n<3||n>4)return null;
        x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
      }else{
        if(n==8||n==6||n<4)return null;
        if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
        d=i(d.slice(1),16);
        if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=Math.round((d&255)/0.255)/1000;
        else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
      }return x
    }; 

    var c = $(this.element).data().color.substring(1);      // strip #
    var rgb = parseInt(c, 16);   // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff;  // extract red
    var g = (rgb >>  8) & 0xff;  // extract green
    var b = (rgb >>  0) & 0xff;  // extract blue

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709    

    let factor = luma > 128 ? -0.2 : 0.2
    document.querySelector(':root').style.setProperty('--Shop-Color', $(this.element).data().color);
    document.querySelector(':root').style.setProperty('--Shop--Hover--Color', pSBC(factor, $(this.element).data().color));
    if($(this.element).data().url.length) {
      $.ajax({url: $(this.element).data().url, dataType: 'script'});
    }
    if(localStorage.getItem('edit_product')) {
      setTimeout(() => {
        try {
          let item = JSON.parse(localStorage.getItem('edit_product'))
          localStorage.removeItem('edit_product')
          productHeader($(`[data-item-id="${item.smartId}"]`))
          $.get(`/basket_products/${item.cartId}/edit`)
        } catch (error) {
        }

      }, 500)

    }

    if($('#shop_show').data().product && $(`#shop_product_${$('#shop_show').data().product}`)) {
      let product = $(`#shop_product_${$('#shop_show').data().product}`)
      productHeader(product)
      $.get(`/${product.data().type}/new?item_id=${product.data().itemId}`)
    }
  }

  show_all() {
    $('.shop_category_item').removeClass('active')
    $(`.shop_category_item[data-category-id="all"]`).addClass('active')
    $('.shop_show_section').toggle(true)
    $('.shop_show_items_container').removeClass('wrapping')
  }

  select_category(e) {
    let id = $(e.currentTarget).data().categoryId
    this.show_all()
    $('#shop_show_search_input').val('')
    $('.show_all_items').removeClass('show_all_items')
    $('hr.shop-show__horizontal-separation').show()
    $('.shop-show__thumbnail-line').show()

    $('.shop_show_items_container').removeClass('wrapping')
    if(id != 'all') {
      $('.shop_shopw_section__see-all').hide()
      $('hr.shop-show__horizontal-separation').hide()
      $('.shop-show__thumbnail-line').hide()
      $('.shop_show_section').hide()

      let section = $(`#shop_section_${id}`)
      section.addClass('show_all_items')
      section.show()
      section.find('.shop_show_see_more').removeClass('d-flex')
      section.find('.shop_show_items_container').addClass('wrapping')
    } else {
      $('.shop_shopw_section__see-all').show()
      $('.shop_show_section').show()
      $('.shop_show_section').filter(function() {
        $(this).toggle($(this).find('.shop_show_item_card:visible').length != 0)
      })
    }
    $('.shop_category_item').removeClass('active')
    $(`.shop_category_item[data-category-id="${id}"]`).addClass('active')
    window.scrollTo(0, 0);
    if($(window).width() > 1200) {
      stickyNav(($(document).scrollTop() - 50) / $(window).height())
    }

  }

  search(e) {
    let value = latinize($('#shop_show_search_input').val().toLowerCase())
    if(value.length > 0) {$('.shop-show__thumbnail-line').hide()
      $('.shop-show__thumbnail-line').hide()
      window.scrollTo(0, 0);
      $('.shop_show_shop_card').hide(200)
      $('.shop_show_item_card').filter(function() {
        $(this).toggle(latinize($(this).data().filter.toLowerCase()).indexOf(value) > -1)
      });
    } else {
      $('.shop_show_shop_card').show()
      this.show_all()
    }

    $('.shop_show_section').filter(function() {
      $(this).toggle($(this).find('.shop_show_item_card:visible').length != 0)
    })
  }

  showHours() {
    let url = `/shops/${$(this.element).data().id}/show_hours`
    if($(this.element).data().type.length) {
      url += `?shop_type=true`
    }
    $.get(url)
  }

  showDescription() {
    let url = `/shops/${$(this.element).data().id}/show_description`
    if($(this.element).data().type.length) {
      url += `?shop_type=true`
    }
    $.get(url)
  }  

  showVitrine(e) {
    let url = `/shops/${$(this.element).data().id}/show_vitrine?vitrine_id=${$(e.currentTarget).data().id}`
    if($(this.element).data().type.length) {
      url += `&shop_type=true`
    }
    $.get(url)    
  }

}
